(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("classnames"), require("react-dom"), require("@floating-ui/react"));
	else if(typeof define === 'function' && define.amd)
		define("ui", ["react", "classnames", "react-dom", "@floating-ui/react"], factory);
	else if(typeof exports === 'object')
		exports["ui"] = factory(require("react"), require("classnames"), require("react-dom"), require("@floating-ui/react"));
	else
		root["ui"] = factory(root["react"], root["classnames"], root["react-dom"], root["@floating-ui/react"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__310__, __WEBPACK_EXTERNAL_MODULE__111__, __WEBPACK_EXTERNAL_MODULE__367__) => {
return 